import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
	ApiRequest,
	LoggerServiceProvider,
	LogLevel,
	OngoingRequest,
	Paging,
	PagingConfig,
} from '@zeiss/ng-vis-common/types';
import { Observable, of } from 'rxjs';
import { Environment } from 'src/environments/environment';
import { Api } from 'src/environments/shared';
import { LogCat } from '../logger/log-cat.enum';
import { LOGGER_SERVICE } from '@zeiss/ng-vis-common/injection-tokens';
import { BaseApiService } from '@zeiss/ng-vis-common/services';

@Injectable({
	providedIn: 'root',
})
export class ApiService implements BaseApiService {
	protected _ongoingRequests: OngoingRequest[] = [];
	ongoingRequests: ApiRequest[] = [];

	constructor(protected http: HttpClient, @Inject(LOGGER_SERVICE) protected logger: LoggerServiceProvider) {}

	public startRequest(url: string, busyId: string): void {
		this.ongoingRequests.push({ url, busyId });
	}

	public endRequest(url: string, busyId: string): void {
		this.ongoingRequests.splice(
			this.ongoingRequests.findIndex((r) => r.url === url && r.busyId === busyId),
			1
		);
	}

	public getBusyUrl(busyId: string): string | undefined {
		return this.ongoingRequests.find((r) => r.busyId === busyId)?.url;
	}

	public getBusyIds(url: string): string[] {
		return this.ongoingRequests.filter((r) => r.url === url).map((r) => r.busyId);
	}

	public busy(urlsOrIds?: string[]): Observable<boolean> {
		if (!urlsOrIds) return of(this.ongoingRequests.length > 0);
		return of(urlsOrIds.some((urlOrId) => this.ongoingRequests.some((r) => r.url === urlOrId || r.busyId === urlOrId)));
	}

	protected LogError(
		error: HttpErrorResponse | string,
		category: LogCat | string,
		silent = false,
		correlationId?: string
	) {
		let msg = '';
		if (typeof error === 'string') {
			msg = error;
		} else if (error.error?.apiStatus) {
			msg = `${error.error.apiStatus}`;
		} else {
			msg = `<b>${error.status}</b>: ${error.statusText}`;
		}

		this.logger.Log(msg, {
			category,
			level: LogLevel.error,
			showToast: !silent,
			correlationId: correlationId,
		} as any);
	}

	protected get HeadersPortalBackend() {
		const headers: { [key: string]: string } = {
			...Api.ESB.Root.Header.PublicWebServices[this._currentEnv],
		};

		return headers;
	}

	protected get HeadersMddbBackend() {
		return {
			...Api.ESB.Root.Header.MDDB[this._currentEnv],
		};
	}

	protected get HeaderPublicWebServices() {
		const headers: { [key: string]: string } = {
			...Api.ESB.Root.Header.PublicWebServices[this._currentEnv],
		};

		return headers;
	}

	protected get HeaderCoreWebServices() {
		return {
			...Api.ESB.Root.Header.CoreWebServices[this._currentEnv],
		};
	}

	protected get HeaderEsbServices() {
		return {
			...Api.ESB.Root.Header.ESB[this._currentEnv],
		};
	}

	protected get _currentEnv() {
		return Environment.AppMeta.Environment;
	}

	StartRequest(type?: string): string {
		const retVal = { id: window.crypto.randomUUID(), type: type ?? '' };
		this._ongoingRequests.push(retVal);
		return retVal.id;
	}

	EndRequest(uuid: string): void {
		const index = this._ongoingRequests.findIndex((x) => x.id === uuid);
		if (index >= 0) {
			this._ongoingRequests.splice(index, 1);
		}
	}

	GetPagingHeaders(headers: HttpHeaders): PagingConfig | undefined {
		if (headers !== undefined) {
			return {
				pIndex: Number.parseInt(headers.get('Paging-pIndex') ?? '0'),
				pSize: Number.parseInt(headers.get('Paging-pSize') ?? '0'),
				pTotal: Number.parseInt(headers.get('Paging-pTotal') ?? '0'),
				tCount: Number.parseInt(headers.get('Paging-tCount') ?? '0'),
			};
		}
		return undefined;
	}

	Busy(types: string[] = []): boolean {
		if (types.length > 0) {
			types = types.map((x) => x.toLowerCase());
			return this._ongoingRequests.filter((x) => types.includes(x.type.toLowerCase())).length > 0;
		}
		return this._ongoingRequests.length > 0;
	}

	addPagingToResponseBody<T>(resp: HttpResponse<T>): void {
		if (!resp.body) {
			return;
		}

		(resp.body as T & { paging: Paging }).paging = {
			pIndex: +(resp.headers.get('paging-pindex') ?? ''),
			pSize: +(resp.headers.get('paging-psize') ?? ''),
			pTotal: +(resp.headers.get('paging-ptotal') ?? ''),
			totalCount: +(resp.headers.get('paging-tcount') ?? ''),
		};
	}
}
